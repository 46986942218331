<template>
  <div class="page page-vip">
    <b-col class="py-3">
      <Breadcrumb :items="[{label: $t('Account'), click: () => $router.push({name: 'Account'})}, {label: $t('VIP')}]"/>
      <LoadingPanel v-if="loading" />
      <div v-else-if="vip">
        <div class="vip-header row">
          <div class="col-12">
            <div class="v-image">
              <img :src="vip.currentRank.imageUrl.getUrl()" />
            </div>
            <div class="v-name">{{vip.currentRank.name}}</div>
          </div>
        </div>
        <div class="vip-body">
          <div class="vip-heading d-flex justify-content-between align-items-center">
            <div class="title">{{$t("Current {0}", [$config.goldName])}}</div>
            <div class="money">{{vip.totalCharged.$toString()}}</div>
          </div>
          <div class="vip-progress d-flex align-items-center">
            <div class="vip-bar" style="flex: 1">
              <b-progress :value="(vip.nextRank == null ? 0 : ((vip.totalCharged/vip.nextRank.money)*100))" height="8"></b-progress>
            </div>
            <div class="vip-image ml-2" v-if="vip.nextRank">
              <img :src="vip.nextRank.imageUrl.getUrl()" />
            </div>
          </div>
          <div class="vip-charge">
            {{$t("Charge {0} {1} to rank {2}", [((vip.nextRank ? vip.nextRank.money : 0) - vip.totalCharged).$toString(), $config.goldName, vip.nextRank ? vip.nextRank.name : ""])}}
          </div>
          <div class="vip-tutorial">
            <a :href="vip.tutorialUrl" class="d-flex align-items-center">
              <img src="../../../assets/images/icon/menu.png" class="img-icon" />
              <div style="flex: 1">{{$t("Charge VIP tutorial", [$config.goldName])}}</div>
              <img src="../../../assets/images/icon/right-arrow.svg" class="img-icon" />
            </a>
          </div>
        </div>
      </div>      
      <NotFoundPanel v-else />
    </b-col>
  </div>
</template>

<script>
import vipService from "@/services/vipService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "Index",
  data() {
    return {
      vip: null,
      loading: true
    }
  },
  async created() {
    const response = await vipService.get();
    if (!response) {
      await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      this.loading = false;
      return;
    }

    if (response.error) {
      await dialogHelper.alert(response.message);
      this.loading = false;
      return;
    }

    this.loading = false;
    this.vip = response.data;
  }
}
</script>

<style scoped lang="scss">
.vip-header {
  background-image: url("../../../assets/images/bg-vip.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 16px 24px 60px 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  
  .v-image {
    max-width: 42%;
    margin: 0 auto;
    
    img {
      width: 100%;
    }
  }
  
  .v-name {
    margin-top: 6px;
    font-weight: 700;
    font-size: 1.5em;
    color: #ffeb7b;
    text-align: center;
  }
}

.vip-body {
  margin: -10px 8px 0 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
  border-radius: 8px;
  padding:  18px;
  
  .vip-heading {
    font-weight: 600;
    font-size: 16px;
    
    .money {
      font-size: 22.5px;
    }
  }
  
  .vip-progress {
    .vip-image img {
      width: 44px;
      height: 34px;
      object-fit: contain;
    }
    
    .vip-bar {
      .progress {
        height: 8px;

        
      }     
    }
  }
}

.vip-charge {
  padding: 4px 0 0;
  font-weight: 600;
  font-size: 13.5px;
  color: rgba(255,117,58,.8);
}

.vip-tutorial {
  margin-top: 13px;
  padding: 12px 0;
  font-size: 15.75px;
  align-items: center;

  div {
    padding-left: 13px;
    padding-top: 5px;
  }
  
  img {
    height: 18px;
  }
}
</style>